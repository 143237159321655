html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    display: table
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


li::before {
    content: "≫ ";
    position: absolute;
    left: -1.25em;
    margin-top: 0.05em;
    font-size: 0.9em;
}

.fine-li::before {
    left: 0.75em;
}

.disabled-link {
  pointer-events: none;
}


.hidden {
    display: none;
}

.votes {
  opacity: 0.33;
  transition: opacity 0.5s ease;
}

.votes:hover {
  opacity: 1;
}

.votes-clicked {
  opacity: 1;
}


.menu-container {
  display: flex;
  justify-content: space-around;
}

.menu-item-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item-title {
  z-index: 0;
  position: absolute;
  top: 0px;
}

.neon-text {
  z-index: 0;
  color: #0ff;
  text-shadow:
    0 0 1px #0ff,
    0 0 2px #0ff,
    0 0 3px #0ff,
    0 0 30px #0ff,
    0 0 55px #0ff,
    0 0 80px #0ff;
}

.menu-item-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.neon-link {
  padding: 0px 10px 0 10px;
  color: #0ff;
  text-decoration: none;
}

.menu-submenu {
  z-index: 1;
  margin-top: 25px;
}

.clampText {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.clampText4Liner {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.rotated {
    transform: rotate(180deg);
}

.arwes-frame-pentagon {
    width: 100%;
}

.surrounding-frame > .arwes-frame__structure > svg > g > g > path {
    opacity: 0;
}


.appearing-image {
  opacity: 0;
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media print {
    .no-print {
        display: none !important;
    }

    .no-print-arwes-frame > .arwes-frame__structure {
        display: none !important;
    }
}

