.glitch-container {
    position: relative;
}

.glitch-item {
    position: relative;
    z-index: 1;
}

.unglitch-effect {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: unglitch infinite;
    animation-duration: 1s;
}


@keyframes unglitch {
    0%, 100% {
        clip-path: polygon(
                0% 0%, 100% 0%, 100% var(--min-value), 0% var(--min-value),
                0% var(--max-value), 100% var(--max-value), 100% 100%, 0% 100%
        );
    }
}

.glitch-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: glitch infinite;
    animation-duration: 1s;
    z-index: 2;
}

@keyframes glitch {
    0%, 100% {
        clip-path: inset(var(--min-value) 0 calc(100% - var(--max-value)) 0);
        transform: translate(var(--transform));
    }

}