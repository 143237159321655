.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
}

.tab-sr6-content {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.tab-sr6-container {
    width: 100%;
    padding: 5px;
}

.two-cols {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}


    .col1 {
        flex: 1 1 50%;
        min-width: 300px;
        box-sizing: border-box;
    }

    .col2 {
        flex: 1 1 50%;
        min-width: 300px;
        box-sizing: border-box;
    }


@media (min-width: 600px) {
    .container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .left-container {
        display: flex;
    }

    .attribute-cards-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
    }

    .tab-sr6-content {
        padding: 5px;
        display: flex;
        flex-direction: row;
    }
}

.growing-img {
    transform: scale(0.8);
    transform-origin: center;
    animation: grow 1s forwards;
}

.pentagonframe-image {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--squareSize)), calc(100% - var(--squareSize)) 100%, 0 100%);
}



@keyframes grow {
    from {
        transform: scale(0.85);
    }
    to {
        transform: scale(1);
    }
}
